<template>
  <b-container fluid>
    <b-card>
      <b-overlay>
        <b-row>
          <b-col cols="12">
            <b-row>
              <b-col
                  cols="12"
                  class="text-right"
              >
                <b-button-group>
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      v-b-modal.certificateBlankSaveModal
                      class=""
                      variant="success"
                      @click="createCertificateBlank"
                  >
                    <feather-icon icon="PlusIcon" />
                    Thêm mới
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-overlay>
      <!--Table-->
      <div class="m-2">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center mb-1 mt-0">
              <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                đến {{ filter.itemsPerPage * (filter.currentPage) }} của {{ totalRows }} bản ghi
              </span>
            </div>
            <vue-good-table
                mode="remote"
                class="my-table"
                row-style-class="vgt-row"
                style-class="vgt-table striped bordered"
                :columns="columns"
                :rows="certificateBlanks"
                :pagination-options="paginationOptions"
                :total-rows="totalRows"
                :line-numbers="true"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
            >
              <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
              >
                Không có bản ghi nào !
              </div>
              <template
                  slot="table-row"
                  slot-scope="props"
              >
                <span v-if="props.column.field === 'status'">
                  {{ getStatusName(props.row.status) }}
                </span>
                <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                <span
                    v-if="props.column.field === 'action'"
                    class="thead-group-action"
                >
                  <b-button-group>
                    <b-button
                        v-b-modal.certificateBlankSaveModal
                        class="btn-icon"
                        variant="primary"
                        @click="updateCertificateBlank(props.row)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <b-button
                        variant="info"
                        class="btn-icon"
                        size="sm"
                        title="Chỉnh sửa mẫu phôi"
                        @click="sampleCertificateBlank(props.row)"
                    >
                      <feather-icon icon="ListIcon" />
                    </b-button>
                    <b-button
                        class="btn-icon"
                        variant="danger"
                        @click="onDeleteCertificateBlank(props.row)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-button-group>
                </span>
              </template>

              <!-- pagination -->
              <template
                  slot="pagination-bottom"
                  slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage-1) +1 }}
                      đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ totalRows }} bản ghi
                    </span>
                  </div>
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị </span>
                    <b-form-select
                        v-model="filter.itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap"> bản ghi/trang</span>
                  </div>
                  <div>
                    <b-pagination
                        :value="1"
                        :total-rows="totalRows"
                        :per-page="filter.itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                    />
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <CertificateBlankSave
        ref="certificateBlankSaveModal"
        :item="currentCertificateBlank"
        @succeed="onSucceed"
    />

    <b-modal
        id="sampleCertificateBlankModal"
        ref="sampleCertificateBlankModal"
        ok-title="Xác nhận"
        cancel-title="Hủy"
        centered
        size="xl"
        :hide-header="true"
        @ok="onSucceed"
    >
      <SampleCertificateBlank :data-send="currentCertificateBlank" />
    </b-modal>
  </b-container>
</template>
<script>

import Ripple from 'vue-ripple-directive'
import {
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer, BDropdown, BDropdownItem,
  BFormGroup,
  BFormSelect,
  BOverlay, BPagination, BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SampleCertificateBlank from '@/views/certificate-blank/SampleCertificateBlank.vue'
import CertificateBlankSave from '@/views/certificate-blank/CertificateBlankSave.vue'

export default {
  name: 'CertificateBlank',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    CertificateBlankSave,
    SampleCertificateBlank,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormGroup,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
      },
      currentCertificateBlank: undefined,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Tên',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
        },
        {
          label: 'Phân loại',
          field: 'type',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
        },
        {
          label: 'Số lượng',
          field: 'number',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Cấu hình số hiệu',
          field: 'codeCredential',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Cấu hình số vào sổ gốc',
          field: 'codeCredentialStorage',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: true,
          filterOptions: {
            placeholder: 'Tất cả',
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.statusFilterDropdownItems,
          },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'certificateBlank/statuses',
      types: 'certificateBlank/types',
      certificateBlanks: 'certificateBlank/certificateBlanks',
      totalRows: 'certificateBlank/totalRows',
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(status => ({
        value: status.value,
        text: status.label,
      }))
    },
    credentialTypeFilterDropdownItems() {
      return this.types.map(type => ({
        value: type.value,
        text: type.label,
      }))
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.readCertificateBlank(this.filter)
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      readCertificateBlank: 'certificateBlank/readCertificateBlanks',
      deleteCertificateBlank: 'certificateBlank/deleteCertificateBlank',
    }),
    createCertificateBlank() {
      this.currentCertificateBlank = undefined
    },
    sampleCertificateBlank(row) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = row
      this.currentCertificateBlank = rest
      this.$refs.sampleCertificateBlankModal.show()
    },
    async getCertificateBlankFromStore() {
      this.isLoading = true
      try {
        await this.readCertificateBlank(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getCertificateBlankFromStore()
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getCertificateBlankFromStore()
    },
    updateCertificateBlank(row) {
      const {
        originalIndex,
        vgt_id,
        ...rest
      } = row
      this.currentCertificateBlank = rest
    },
    onDeleteCertificateBlank(certificateBlank) {
      this.$swal({
        title: 'Bạn có chắc chắn muốn xóa?',
        text: `Xóa ${certificateBlank.description}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          const response = await this.deleteCertificateBlank(certificateBlank.id)
          const { isSuccessful, message } = response
          if (response) {
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              try {
                await this.readCertificateBlank(this.filter)
              } catch (e) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `[${e.code}] ${e.message}`,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
          try {
            await this.deleteCertificateBlank(certificateBlank.id)
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    async onSucceed() {
      await this.getCertificateBlankFromStore()
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
  },
}
</script>

<style scoped lang="scss">

</style>

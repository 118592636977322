<template>
  <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
  >
    <b-modal
        id="certificateBlankSaveModal"
        body-class="position-static"
        centered
        :title="isCreated ? 'Thêm mới dữ liệu' : 'Cập nhật dữ lệu'"
        no-close-on-backdrop
        @show="onShow"
        @hidden="onHide"
    >
      <b-form>
        <b-form-group label-for="name">
          <template v-slot:label>
            Tên phôi văn bằng/chứng chỉ<span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Tên phôi văn bằng/chứng chỉ"
              rules="required"
          >
            <b-form-input
                id="name"
                v-model="targetItem.name"
                placeholder="Tên phôi văn bằng/chứng chỉ"
                :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="description">
          <template v-slot:label>
            Mô tả loại phôi văn bằng/chứng chỉ<span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Mô tả loại phôi văn bằng/chứng chỉ"
              rules="required"
          >
            <b-form-input
                id="description"
                v-model="targetItem.description"
                placeholder="Mô tả loại phôi văn bằng/chứng chỉ"
                :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="type">
          <template v-slot:label>
            Phân loại <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Phân loại"
              rules="required"
          >
            <v-select
                v-model="targetItem.credentialTypeId"
                :options="allCredentialTypes"
                :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="number">
          <template v-slot:label>
            Số lượng của loại phôi văn bằng/chứng chỉ<span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Số lượng của loại phôi văn bằng/chứng chỉ"
              rules="required"
          >
            <b-form-input
                id="description"
                v-model="targetItem.number"
                type="number"
                placeholder="Số lượng của loại phôi văn bằng/chứng chỉ"
                :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="codeCredential">
          <template v-slot:label>
            Cấu hình số hiệu<span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Mã bắt đầu của loại phôi văn bằng/chứng chỉ"
              rules="required"
          >
            <b-form-input
                id="codeCredential"
                v-model="targetItem.codeCredential"
                placeholder="Mã bắt đầu của loại phôi văn bằng/chứng chỉ"
                :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label-for="codeCredentialStorage">
          <template v-slot:label>
            Cấu hình số vào sổ gốc<span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Mã kết thúc của loại phôi văn bằng/chứng chỉ"
              rules="required"
          >
            <b-form-input
                id="codeCredentialStorage"
                v-model="targetItem.codeCredentialStorage"
                placeholder="Mã kết thúc của loại phôi văn bằng/chứng chỉ"
                :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group label-for="status">
          <template v-slot:label>
            Trạng thái <span class="text-danger">*</span>
          </template>
          <validation-provider
              #default="{ errors }"
              name="Trạng thái"
              rules="required"
          >
            <v-select
                v-model="targetItem.status"
                :options="statusOptions"
                :reduce="option => option.value"
            />
            <b-form-invalid-feedback :state="getElementState(errors)">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onSave('hide')"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Lưu lại
            </span>
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('certificateBlankSaveModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>
<script>

import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BModal, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import { required } from '@core/utils/validations/validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CertificateBlankSave',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      targetItem: {
        name: '',
        description: '',
        credentialTypeId: 0,
        type: '',
        number: 0,
        codeCredential: '',
        codeCredentialStorage: '',
        linkFileCover: '',
        linkFileMain: '',
        fileCoverId: 0,
        fileMainId: 0,
        status: 1,
      },
      required,
    }
  },
  computed: {
    ...mapGetters({
      statuses: 'certificateBlank/statuses',
      types: 'certificateBlank/types',
      allCredentialTypes: 'dropdown/allCredentialTypes',
    }),
    statusOptions() {
      return this.statuses
    },
    isCreated() {
      return !this.item
    },
  },
  methods: {
    ...mapActions({
      createCertificateBlank: 'certificateBlank/createCertificateBlank',
      updateCertificateBlank: 'certificateBlank/updateCertificateBlank',
      getAllCredentialTypes: 'dropdown/getAllCredentialTypes',
    }),
    async onSave(type = null) {
      try {
        const response = this.isCreated ? await this.createCertificateBlank(this.targetItem) : await this.updateCertificateBlank(this.targetItem)
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (type === 'hide') {
              this.$bvModal.hide('certificateBlankSaveModal')
            }
            this.$emit('succeed')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async onShow() {
      await this.getAllCredentialTypes()
      if (this.item) {
        this.targetItem = { ...this.item }
      }
    },
    onHide() {
      this.targetItem = {
        name: '',
        description: '',
        credentialTypeId: 0,
        type: '',
        number: 0,
        codeCredential: '',
        codeCredentialStorage: '',
        linkFileCover: '',
        linkFileMain: '',
        status: 1,
      }
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>
<style scoped lang="scss">
</style>
